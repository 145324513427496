import { navigate } from "gatsby-link"
import React, { useContext, useState } from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import LoginForm from "../components/LoginForm"
import TwoCol from "../components/TwoCol"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"
import axios from "axios"

const StyledIndexPage = styled.div``

const IndexPage = () => {
  const dispatch = useContext(GlobalDispatchContext)
  const [loginDetails, setLoginDetails] = useState({ email: "", password: "" })

  const handleSubmit = e => {
    e.preventDefault()
    dispatch({ type: "LOADING", value: true })
    axios
      .post(`${process.env.GATSBY_API}no-auth/login`, loginDetails)
      .then(res => {
        dispatch({ type: "LOGIN", user: res.data.user, token: res.data.token })
        navigate("/filer")
      })
      .catch(err => {
        dispatch({ type: "LOADING", value: false })
        console.log(err.response)
        dispatch({ type: "ALERT", content: "Fel e-post eller lösenord" })
      })
  }

  return (
    <Layout>
      <StyledIndexPage>
        <TwoCol className="content-width">
          <div className="left-content">
            <h2>Välkommen till kundportalen.</h2>
            <p className="light">
              Här kan du som kund logga in till vår kundportal.
              <br />
              Om du inte har fått några inloggningsuppgifter så är du välkommen
              att vända dig till info@h2oteknik.se
            </p>
          </div>
          <LoginForm
            data={loginDetails}
            setData={setLoginDetails}
            handleSubmit={handleSubmit}
          />
        </TwoCol>
      </StyledIndexPage>
    </Layout>
  )
}

export default IndexPage
