import React from "react"
import styled from "styled-components"
import { breakpoints, colors } from "../utils/siteVars"
import Button from "./Button"

const StyledLoginForm = styled.form`
  background: ${colors.white};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 4px 4px 12px 4px rgba(0, 0, 0, 0.2);
  input {
    width: 100%;
  }
  h3 {
    margin: 0;
    margin-bottom: 1rem;
    text-align: center;
    color: ${colors.darkGrey};
  }
  ${breakpoints.mobile} {
    text-align: center;
    align-items: center;
  }
`

const LoginForm = ({ data, setData, handleSubmit }) => {
  return (
    <StyledLoginForm onSubmit={handleSubmit}>
      <h3>Logga in som kund</h3>
      <input
        type="text"
        placeholder="E-post"
        name="email"
        value={data.email}
        onChange={e => setData({ ...data, [e.target.name]: e.target.value })}
      />
      <input
        type="password"
        placeholder="Lösenord"
        name="password"
        value={data.password}
        onChange={e => setData({ ...data, [e.target.name]: e.target.value })}
      />
      <Button>Logga in</Button>
    </StyledLoginForm>
  )
}

export default LoginForm
